@media (max-width: 425px){
	.home .hero .lettering .row .major {
		font-size: 68px;
	}
	.home .hero .lettering .row .minor {
		font-size: 42px;
	}

    .home .bekatsu{
		padding-top: 180px;
    }

    .home .bekatsu .content{
        width: 90%;
        flex-direction: column;
        align-items: center;
    }
    
    .home .bekatsu .content .left{
        align-items: center;
        text-align: center;
        width: 100%;
    }
    
    .home .bekatsu .content .right{
        width: 100%;
    }

    .home .bekatsu .content .left .texts .title{
        font-size: 32px;
		font-weight: bold;
    }

	.home .bekatsu .content .left .texts .title .white {
		font-size: 48px;
	}

    .home .bekatsu .content .left .texts .description{
        width: 100%;
        font-size: 14px;
    }
    
    .home .bekatsu .content .right .types{
        display: none;
    }

    .home .partners{
        display: flex;
        margin-top: 100px;
        margin-bottom: 100px;
    }
    
    .home .partners .content{
        width: calc(100% - 64px)!important;
    }
    
    .home .partners .content .title{
        font-size: 32px;
		font-weight: bold;
    }

    .home .partners .content .list .partner{
        width: 40.55555555555556%;
        margin-right: 18.88888888888889%;
    }

	.home .partners .list .partner:nth-child(2n) {
		margin-right: 0;
	}

    .home .contact .content{
        width: calc(100% - 32px);
    }

    .home .contact .content .title{
        font-size: 32px;
		font-weight: bold;
    }
}

@media (max-width: 320px) {
	.home .bekatsu .content .left .texts .title{
        font-size: 28px;
    }

	.home .hero .lettering .row .major {
		font-size: 56px;
	}
	.home .hero .lettering .row .minor {
		font-size: 32px;
	}
}