@font-face {
    font-family: 'IntegralCF';
    src: url("./assets/fonts/IntegralCF-Bold.woff2") format('woff2'),
        url("./assets/fonts/IntegralCF-Bold.woff") format('woff');
    font-weight: bold;
	font-display: swap;
}

@font-face {
    font-family: 'IntegralCF';
    src: url("./assets/fonts/IntegralCF-Heavy.woff2") format('woff2'),
        url("./assets/fonts/IntegralCF-Heavy.woff") format('woff');
    font-weight: 900;
	font-display: swap;
}

@font-face {
	font-family: 'Space Grotesk';
	src: url('./assets/fonts/SpaceGrotesk-Light.woff') format('woff');
	font-weight: 300;
	font-display: swap;
}

@font-face {
	font-family: 'Space Grotesk';
	src: url('./assets/fonts/SpaceGrotesk-Medium.woff') format('woff');
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'Space Grotesk';
	src: url('./assets/fonts/SpaceGrotesk-Regular.woff') format('woff');
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: 'Space Grotesk';
	src: url('./assets/fonts/SpaceGrotesk-SemiBold.woff') format('woff');
	font-weight: 600;
	font-display: swap;
}

@font-face {
	font-family: 'Space Grotesk';
	src: url('./assets/fonts/SpaceGrotesk-Bold.woff') format('woff');
	font-weight: 700;
	font-display: swap;
}

body{
    background-color: black;
    font-family: 'Space Grotesk', sans-serif;
    color: #ededed;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
}

html, body {
	overflow-x: hidden
}
/* 
#root{
    width: 100%;
    display: flex;
    flex-direction: column;
} */

main {
	position: relative;
}

main::before {
	content: "";
	position: absolute;
	inset: 0;
	background: black;
	opacity: 0;
	transition: .7s cubic-bezier(0.25, 1, 0.5, 1);
	transition-delay: .2s;
	z-index: 199;
	pointer-events: none;
}

main.out::before {
	opacity: 1;
}

.wrap {
	max-width: 1320px;
	width: 100%;
	height: 100%;
	display: flex;
    align-items: center;
    justify-content: space-between;
	margin: 0 auto;
}

section:not(.hero) {
	width: 100%;
	padding: 180px 0;
}

@media (max-width: 425px) {
	section:not(.hero) {
		padding: 90px 0;
	}
}