@media (max-width: 425px) {
    .projects .page{
        align-items: center;
    }

    .projects .page .title{
        font-size: 48px !important;
    }

    .projects .page .list .project{
        width: 90%;
        flex-direction: column;
    }
    
    .projects .page .list .project:nth-child(even){
        flex-direction: column;
    }
    
    .projects .page .list .project .images{
        width: 50%;
        display: flex;
    }
    
    .projects .page .list .project .images .top,.projects .page .list .project:nth-child(even) .images .top{
        transform: skew(-15deg);
        width: 75%;
        z-index: 50;
        margin-left: 0px;
    }
    
    .projects .page .list .project .images .top img,.projects .page .list .project:nth-child(even) .images .top img{
        transform: skew(15deg) scale(1.25);
    }

    .projects .page .list .project.left:hover .images .top{
        transform: skew(-15deg) !important;
    }
    
    .projects .page .list .project.left:hover .images .bottom{
        transform: skew(15deg) !important;
    }
    
    .projects .page .list .project:nth-child(even):hover .images .top{
        transform: skew(-15deg) !important;
    }
    
    .projects .page .list .project:nth-child(even):hover .images .bottom{
        transform: skew(15deg) !important;
    }
    
    .projects .page .list .project .images .bottom,.projects .page .list .project:nth-child(even) .images .bottom{
        transform: skew(15deg);
        margin-top: -80px;
        margin-left: 40px;
        overflow: hidden;
        width: 75%;
    }
    
    .projects .page .list .project .images .bottom img,.projects .page .list .project:nth-child(even) .images .bottom img{
        transform: skew(-15deg) scale(1.25);
    }
    
    .projects .page .list .project img{
        width: 100%;
    }
    
    .projects .page .list .project.left:hover .images .top,.projects .page .list .project:nth-child(even):hover .images .top{
        transform: translateY(-65px) skew(-15deg);
    }
    
    .projects .page .list .project.left:hover .images .bottom,.projects .page .list .project:nth-child(even):hover .images .bottom{
        transform: translateY(65px) skew(15deg);
    }
    
    .projects .page .list .project .data{
        margin-top: 30px;
        margin-bottom: 50px;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0px;
        align-items: center;
        justify-content: space-between;
        text-align: center;
    }
    
    .projects .page .list .project .data .name{
        font-size: 48px !important;
        font-weight: bold;
    }
    
    .projects .page .list .project .data .type{
        font-size: 18px;
        font-weight: bold;
        width: 90%;
    }
}

@media (max-width: 320px) {
	.projects .page .title{
        font-size: 42px !important;
    }
	.projects .page .list .project .data .name {
		font-size: 36px !important;
	}
}