.projects{
    display: flex;
    flex-direction: column; 
    width: 100%;
}

.projects .page{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.projects .page .title {
    width: 100%;
    height: 1080px;
    background-image: url("../../assets/images/Projects.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 128px;
    font-weight: 900;
	font-family: "IntegralCF";
    color: #EDEDED;
}

.projects .page .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
	margin-top: -76px;
}

.projects .page .list .project{
    width: 90%;
    max-width: 1080px;
    display: flex;
    align-items: center;
    cursor: pointer;
	padding: 90px 0;
}

.projects .page .list .project:nth-child(even){
    flex-direction: row-reverse;
}

.projects .page .list .project .images{
    width: 45%;
    display: flex;
    flex-direction: column;
}

.projects .page .list .project .images > div {
	width: 75%;
	transition: .6s cubic-bezier(0.25, 1, 0.5, 1);
	overflow: hidden;
	aspect-ratio: 1 / 1;
}

.projects .page .list .project .images .top{
    transform: skew(-15deg);
    z-index: 50;
	box-shadow: 0 20px 30px rgb(0 0 0 / 70%);
}

.projects .page .list .project .images .top img{
    transform: translateX(-11px) skew(15deg) scale(1.35);
}

.projects .page .list .project:nth-child(even) .images .top{
    transform: skew(15deg);
    margin-left: 150px;
}

.projects .page .list .project:nth-child(even) .images .top img{
    transform: translateX(11px) skew(-15deg) scale(1.35);
}

.projects .page .list .project .images .bottom{
    transform: skew(15deg);
    z-index: 25;
    margin-top: -150px;
    margin-left: 150px;
}

.projects .page .list .project .images .bottom img{
    transform: skew(-15deg) scale(1.3);
}

.projects .page .list .project:nth-child(even) .images .bottom{
    transform: skew(-15deg);
    margin-left: 0px;
}

.projects .page .list .project:nth-child(even) .images .bottom img{
    transform: skew(15deg) scale(1.3);
}

.projects .page .list .project img{
    width: 100%;
}

.projects .page .list .project .data{
    width: 55%;
	padding: 125px 0 125px 90px;
}

.projects .page .list .project:nth-child(even) .data {
	text-align: right;
	padding: 125px 60px 125px 0;
}

.projects .page .list .project .data .name{
    font-size: 56px;
    font-weight: 900;
	font-family: "IntegralCF";
	line-height: 1;
	margin-bottom: 20px;
}

.projects .page .list .project .data .type{
    opacity: .4;
    font-size: 18px;
    font-weight: bold;
	letter-spacing: 1px;
}

@media (min-width: 1020px) {
	.projects .page .list .project:nth-child(odd):hover .images .top{
		transform: translateY(-5%) skew(-15deg);
		box-shadow: 0 40px 30px rgb(0 0 0 / 60%);
	}

	.projects .page .list .project:nth-child(odd):hover .images .bottom{
		transform: translateY(10%) skew(15deg);
	}

	.projects .page .list .project:nth-child(even):hover .images .top{
		transform: translateY(-5%) skew(15deg);
		box-shadow: 0 40px 30px rgb(0 0 0 / 60%);
	}

	.projects .page .list .project:nth-child(even):hover .images .bottom{
		transform: translateY(10%) skew(-15deg);
	}
}