.btn-container{
    display: flex;
}

.btn{
    padding: 20px;
    position: relative;
    font-size: 21px;
    font-weight: bold;
    color: #EDEDED;
    cursor: pointer;
    letter-spacing: 1px;
    display: inline;
}

.btn .arrow{
    width: 35px;
    transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 0px;
    right: -10px;
}

.btn .arrow img{
    width: 234px;
    height: 23px;
}

.btn:hover .arrow{
    width: 70px;
}

@media (max-width: 425px) {
    .btn-container{
        justify-content: center !important;
    }

    .btn{
        font-size: 16px;
    }
}