.footer{
    width: 100%;
    height: 350px;
    background-image: url("../../assets/images/Footer.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    overflow: hidden;
    padding-left: 100px;
    padding-right: 100px;
	padding-bottom: 68px;
	display: flex;
	align-items: flex-end;
}

.footer .wrap {
	height: 51px;
}

.footer .wrap > * {
	width: 33.3333%;
}

.footer .socialmedias > div{
    display: flex;
    width: 100px;
    align-items: center;
    justify-content: space-between;
}

.footer .socialmedias > div img{
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.footer .copyright{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    text-align: center;
}

.footer .copyright .email{
    color: #EDEDED;
    margin-bottom: 5px;
}

.footer .copyright .namingrights{
    color: #646464;
	margin-top: 2px;
}

.footer .logo{
	display: flex;
	justify-content: flex-end;
}

.footer .logo img{
    width: 26px;
    height: 51px;
}

@media (max-width: 768px) {
    .footer{
        padding-left: 25px;
        padding-right: 25px;
    }

	.footer .wrap{
        flex-direction: column;
		height: auto;
    }

	.footer .wrap > * {
		width: auto;
		display: block;
	}

    .footer .socialmedias{
        margin-top: 140px;
    }

    .footer .copyright{
        margin-top: 26px;
    }

    .footer .logo{
        margin-top: 42px;
    }
}