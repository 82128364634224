@media (max-width: 875px) {
    .home .bekatsu .content{
        width: 90%;
    }   
}

@media (max-width: 768px){
	.home .hero .lettering .row:last-child {
		margin-top: -24px;
	}
	.home .hero .lettering .row .major {
		font-size: 90px;
	}
	.home .hero .lettering .row .minor {
		font-size: 56px;
	}

    .home .bekatsu .content{
        width: 90%;
        flex-direction: column;
        align-items: center;
    }
    
    .home .bekatsu .content .left{
        align-items: center;
        text-align: center;
        width: 100%;
    }
    
    .home .bekatsu .content .right{
        width: 40%;
        overflow: visible;
    }

    .home .bekatsu .content .left .texts {
        justify-content: center;
        align-items: center;
        margin-bottom: 90px;
    }

    .home .bekatsu .content .left .texts .title{
        font-size: 52px;
        text-align: center;
        justify-content: center;
    }

    .home .bekatsu .content .left .texts .description{
        width: 77.5%;
    }
    
    .home .bekatsu .content .right .types{
        display: none;
    }

    .home .partners{
        display: flex;
        margin-top: 100px;
        margin-bottom: 100px;
    }
    
    .home .partners .content{
        width: calc(100% - 64px);
    }
    
	.home .partners .list .partner {
		width: 14.48863636363636%;
		margin-right: 14.01515151515152%;
	}

    .home .contact .content{
        width: 60%;
    }
}

@media (max-width: 640px) {
	.home .hero .lettering .row,
	.home .hero .lettering .row:first-child {
		flex-direction: column;
		align-items: center;
	}
	.home .hero .lettering .row:first-child .minor,
	.home .hero .lettering .row:last-child .minor {
		margin: 0;
		line-height: .6;
	}
	.home .hero .lettering .row:last-child {
		margin-top: 24px;
	}
}