.projectunique{
    display: flex;
    flex-direction: column; 
    width: 100%;
    align-items: center;
}

.projectunique .video{
    width: 100%;
	max-height: 100vh;
	aspect-ratio: 16 / 9;
    display: flex;
    justify-content: center;
    align-items: center;
	overflow: hidden;
}

.projectunique .video img{
    width: 100%;
}

.projectunique .video video{
    width: 100%;
}

.projectunique .data{
    width: calc(100% - 64px);
	max-width: 1098px;
    margin-top: 180px;
    display: flex;
	flex-flow: row wrap;
}

.projectunique .data .left{
    width: 60%;
    display: flex;
    flex-direction: column;
}

.projectunique .data .right{
    width: 40%;
	text-align: right;
}

.projectunique .data .title{
	width: 100%;
    font-size: 90px;
    font-weight: bold;
    color: #EDEDED;
    font-family: "IntegralCF";
    margin-bottom: 40px;
	line-height: 1;
}

.projectunique .data .left .description{
    font-size: 18px;
	opacity: .6;
	line-height: 1.5;
}

.projectunique .data .right .type{
    opacity: .2;
    margin-bottom: 16px;
    font-size: 42px;
    font-weight: bold;
}

.projectunique .slideshow{
    width: 100%;
    margin-top: 100px;
    display: flex;
}

.projectunique .slideshow .content{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 50;
}

.projectunique .slideshow .content .slide{
    width: 24.25%;
    aspect-ratio: 16 / 9;
    display: none;
    color: white;
    overflow: hidden;
}

.projectunique .slideshow .content .slide.show{
    display: block;
}

.projectunique .slideshow .content .slide img{
    width: 100%;
}

.projectunique .slideshow .content .slide video{
    width: 100%;
    transform: scale(1.8);
}

.projectunique .slideshow .cursors{
    width: 100%;
    height: 300px;
    position: absolute;
    z-index: 100;
    display: flex;
}

.projectunique .slideshow .cursors .left{
    width: 50%;
    height: 100%;
    cursor: url("../../assets/images/CursorLeft.png"), auto;
}

.projectunique .slideshow .cursors .right{
    width: 50%;
    height: 100%;
    cursor: url("../../assets/images/CursorRight.png"), auto;
}