main {
    width: 100%;
}

.home .hero{
	position: relative;
    width: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
	overflow: hidden;
}

.home .hero::before {
	content: "";
	position: absolute;
	inset: 0;
	background: black;
	opacity: .7;
	transition: .7s cubic-bezier(0.25, 1, 0.5, 1);
	transition-delay: .2s;
}

.home .hero .lettering {
	position: absolute;
	left: 50%;
	top: 47%;
	transform: translate(-50%,-50%);
	max-width: 1100px;
	width: 81.25%;
	font-family: "IntegralCF";
	font-weight: 900;
}

.home .hero.out .lettering .row:first-child {
	opacity: 0;
	transform: translateX(-15%);
}

.home .hero.out .lettering .row:last-child {
	opacity: 0;
	transform: translateX(15%);
}

.home .hero .lettering .row {
	width: 100%;
	display: flex;
	transition: .7s cubic-bezier(0.25, 1, 0.5, 1);
	transition-delay: .4s;
}

.home .hero .lettering .row:first-child {
	align-items: flex-end;
}
.home .hero .lettering .row:first-child .minor {
	margin-bottom: 6px;
}

.home .hero .lettering .row:last-child {
	justify-content: flex-end;
	margin-top: -35px;
}
.home .hero .lettering .row:last-child .minor {
	margin-top: 35px;
}

.home .hero .lettering .row .minor {
	font-size: 68px;
	margin-right: 32px;
	opacity: .4;
}

.home .hero .lettering .row .major {
	font-size: 160px;
}

@media (max-width: 1024px) {
	.home .hero .lettering {
		max-width: 892px;
	}
	.home .hero .lettering .row .major {
		font-size: 128px;
	}
}

.home .hero video{
    width: 100%;
    height: 100%;
	object-fit: cover;
}

.home .bekatsu{
	padding-top: 268px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-image: radial-gradient(rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.041), black, black);
    background-size: 125% 125%;
    background-position-x: 250%;
    background-position-y: 250%;
    background-repeat: no-repeat;
}


/* SCROLL */

.home .bekatsu .content .left > *,
.home .bekatsu .content .right {
	transition: .7s cubic-bezier(0.25, 1, 0.5, 1);
}

.home .bekatsu.out .content .left > * {
	transform: translateY(50%);
	opacity: 0;
}

.home .bekatsu .content .left .parallax {
	transition-delay: .1s
}

.home .bekatsu.out .content .right {
	transform: translateX(50%);
	opacity: 0;
}


.home .bekatsu .content{
    z-index: 50;
    width: 91%;
    max-width: 876px;
    display: flex;
    justify-content: space-between;
}

.home .bekatsu .content .left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 65%;
}

.home .bekatsu .content .right{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 35%;
}

.home .bekatsu .content .left .texts{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.home .bekatsu .content .left .texts .title{
	position: relative;
    font-size: 51px;
    font-weight: bold;
    margin-bottom: 15px;
    font-family: "IntegralCF";
	font-weight: 900;
    margin-bottom: 26px;
    mix-blend-mode: color-dodge;
    background-color: transparent;
}

.home .bekatsu .to-be {
	position: relative;
	width: 100%;
	overflow: hidden;
	margin-right: 20px;
	transition-duration: .4s;
}

.home .bekatsu .to-be .scroll {
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	flex-direction: column;
	transition-duration: .5s;
}

.home .bekatsu .to-be,
.home .bekatsu .to-be .scroll {
	transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
}

.home .bekatsu .to-be .scroll.animSecond,
.home .bekatsu .to-be .scroll.animSecondBack {
	transform: translateY(-33.333%);
}
.home .bekatsu .to-be .scroll.animThird {
	transform: translateY(-66.667%);
}

.home .bekatsu .to-be .scroll span {
	display: inline-block;
	background: linear-gradient(to right, rgba(255,255,255,.3), rgba(255,255,255,0));
    background-size: 160%;
    background-position: 70% 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
	white-space: nowrap;
}

.home .bekatsu .to-be .scroll.gradient span {
	animation: tobeGradient 2s alternate infinite;
}

@keyframes tobeGradient {
	from {
		background-position: 10% 0;
	}

	to {
		background-position: 60% 0;
	}
}

.home .bekatsu .content .left .texts .title .white{
    color: #EDEDED;
	display: block;
	font-size: 68px;
	line-height: .85;
}

.home .bekatsu .content .left .texts .description{
    font-size: 18px;
    color: #EDEDED;
    width: 100%;
    opacity: 70%;
    line-height: 1.6;
}

.home .bekatsu .content .left .parallax{
    width: 100%;
	max-width: 539px;
}

.home .bekatsu .content .left .parallax img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.home .bekatsu .content .right .types{
    width: 100%;
    overflow: hidden;
	margin-top: 26px;
}

.home .bekatsu .content .right .types .type{
	display: flex;
    font-size: 26px;
	justify-content: flex-end;
	align-items: center;
    font-weight: bold;
    color: #EDEDED;
    font-family: "IntegralCF";
	font-weight: bold;
	margin-bottom: 10px;
    transition: all .2s ease-in-out;
	opacity: .25;
}

.home .bekatsu .content .right .types .type::before {
	content: "";
	height: 1px;
	background: white;
	width: 0;
	transition: .4s cubic-bezier(0.25, 1, 0.5, 1);
	margin-right: 10px;
	margin-top: 5px;
}

@media (min-width: 769px) {
	.home .bekatsu .content .right .types .type:hover{
		opacity: 1;
	}
	.home .bekatsu .content .right .types .type:hover::before{
		width: 50px;
	}
}

.home .partners{
    width: 100%;
	display: flex;
    justify-content: center;
}

.home .partners .content{
    width: 91%;
    max-width: 876px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home .partners .content .title{
    font-size: 52px;
    font-weight: bold;
    margin-bottom: 100px;
    color: #EDEDED;
    font-family: "IntegralCF";
	font-weight: 900;
}

.home .partners .list{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.home .partners .list .partner{
	width: 14.61187214611872%;
    margin-right: 13.85083713850837%;
    opacity: 50%;
    transition: 0.3s all ease-in-out;
    margin-bottom: 50px;
}

.home .partners .list .partner:nth-child(4n) {
	margin-right: 0;
}

.home .partners .list .partner img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (min-width: 1020px) {
	.home .partners .list .partner:hover{
		opacity: 100%;
	}
}

.home .contact{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
} 

.home .contact .content{
    width: 91%;
    max-width: 430px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home .contact .content .title{
    font-size: 52px;
    font-weight: bold;
    color: #EDEDED;
    margin-bottom: 100px;
    font-family: "IntegralCF";
	font-weight: 900;
}

.home .contact .content .texts{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.home .contact .content .texts input{
    background-color: transparent;
    border: 0px;
    border-bottom: 1px solid #EDEDED;
    padding: 0px;
    padding-bottom: 10px;
    transition: all 0.3s ease-in-out;
    opacity: 40%;
    font-size: 21px;
    outline: none;
    color: #EDEDED;
    margin-bottom: 50px;
}

.home .contact .content .texts input::placeholder{
    color: #EDEDED;
    opacity: 1;
    font-size: 21px;
    font-family: 'Space Grotesk', sans-serif;
}

.home .contact .content .texts input:focus{
    opacity: 100%;
}

.home .contact .content .texts textarea{
    background-color: transparent;
    border: 0px;
    border-bottom: 1px solid #EDEDED;
    padding: 0px;
    padding-bottom: 10px;
    transition: all 0.3s ease-in-out;
    opacity: 40%;
    font-size: 21px;
    outline: none;
    color: #EDEDED;
    margin-bottom: 50px;
}

.home .contact .content .texts textarea::placeholder{
    color: #EDEDED;
    opacity: 1;
    font-size: 21px;
    font-family: 'Space Grotesk', sans-serif;
}

.home .contact .content .texts textarea:focus{
    opacity: 100%;
}