@media (max-width: 768px) {
    .projectunique .data{
        width: 90%;
        flex-direction: column;
    }
    
    .projectunique .data .left{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .projectunique .data .right{
        margin-top: 20px;
        width: 100%;
		text-align: center;
    }
    
    .projectunique .data .title{
        font-size: 90px;
        text-align: center;
        margin-bottom: 40px;
    }
    
    .projectunique .data .left .description{
        text-align: center;
        margin-bottom: 30px;
    }
    
    .projectunique .slideshow{
        height: 100%;
        display: flex;
    }
    
    .projectunique .slideshow .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    
    .projectunique .slideshow .content .slide{
        width: 90%;
        height: 100%;
        margin-top: 30px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    
    .projectunique .slideshow .content .slide.show{
        display: flex !important;
    }
    
    .projectunique .slideshow .content .slide img{
        width: 100%;
    }
    
    .projectunique .slideshow .content .slide video{
        width: 100%;
    }
    
    .projectunique .slideshow .cursors{
        display: none !important;
    }
}