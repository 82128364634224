@media (max-width: 425px) {
	.projectunique .video {
		margin-top: 77px;
	}

    .projectunique .data{
        margin-top: 68px;
    }
    
    .projectunique .data .left{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .projectunique .data .right{
        margin-top: 20px;
        width: 100%;
    }

	.projectunique .data .right .type {
		font-size: 32px;
	}
    
    .projectunique .data .title{
        font-size: 40px !important;
        text-align: center;
        margin-bottom: 40px;
    }
    
    .projectunique .data .left .description{
        text-align: center;
        margin-bottom: 30px;
        font-size: 14px !important;
    }
    
    .projectunique .slideshow{
        margin-top: 68px;
    }
    
    .projectunique .slideshow .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    
    .projectunique .slideshow .content .slide{
        width: 90%;
        height: 100%;
        margin-top: 30px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    
    .projectunique .slideshow .content .slide.show{
        display: flex !important;
    }
    
    .projectunique .slideshow .cursors{
        display: none !important;
    }
}