.navbar{
	position: fixed;
	height: 77px;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.514), rgba(0, 0, 0, 0.082),  transparent);
    z-index: 200;
}

.navbar > * {
	transition: .7s cubic-bezier(0.25, 1, 0.5, 1);
	transition-delay: .5s;
}

.navbar.out > * {
	transform: translateY(-100%);
	opacity: 0;
}

@media (max-width: 1340px) and (min-width: 769px) {
	.navbar .wrap {
		width: calc(100% - 64px);
	}
}

.navbar .logo{
    cursor: pointer;
}

.navbar .logo img{
    width: 93px;
    height: 51px;
}

.navbar .mobilelogo{
    cursor: pointer;
    display: none;
}

.navbar .mobilelogo img{
    width: 26px;
    height: 51px;
}

.navbar .options{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar .options .navigation{
    margin-right: 40px;
    display: flex;
}

.navbar .options .navigation a{
    color: white;
    text-decoration: none;
    display: block;
    position: relative;
    padding-bottom: 5px;
    margin-left: 25px;
    margin-right: 25px;
    font-weight: bold;
    font-size: 14px;
    margin-top: 5px;
}

.navbar .options .navigation a::after{
    content: "";
    position: absolute;
    height: 1px;
    background-color: white;
    bottom: 0px;
    left: 50%;
    right: 50%;
    transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}

.navbar .options .navigation a:hover::after{
    left: 0%;
    right: 0%;
}

.navbar .options .language{
    display: flex;
    align-items: center;
}

.navbar .options .language button{
    margin-left: 5px;
    margin-right: 5px;
    width: 32px;
    height: 32px;
    font-size: 14px;
    color: #EDEDED;
    border: 1px solid transparent;
    background-color: transparent;
    cursor: pointer;
    font-weight: bold;
    opacity: 60%;
}

.navbar .options .language button.selected{
    color: white !important;
    border: 1px solid white !important;
    opacity: 100%;
}

.navbar .mobileoptions{
    display: none;
}

.navbar .mobileoptions .show img{
    width: 48px;
    height: 25px;
}

.navbar .mobilemenu{
    display: none;
}

.navbar .mobilelanguage{
    display: none;
}

@media (max-width: 768px) {
    .navbar{
        padding: 10px;
        padding-left: 30px;
        padding-right: 30px;
        justify-content: space-between;
        align-items: center;
    }

    .navbar .logo{
        z-index: 100;
    }

    .navbar .mobilelogo{
        z-index: 100;
        display: none;
    }

    .navbar .options{
        display: none;
    }

    .navbar .mobileoptions{
        display: block;
        z-index: 100;
    }

    .navbar .mobileoptions a.hide{
        display: none;
    }

    .navbar .mobilemenu{
        background-color: black;
        width: 100%;
        height: 100vh;
        position: fixed;
        z-index: 50;
        /* padding: 150px; */
        padding-top: 35%;
        padding-bottom: 35%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        display: none;
        left: 0;
        top: 0;
    }

    .navbar .mobilemenu a{
        color: #EDEDED;
        text-decoration: none;
        font-size: 56px;
        font-family: "IntegralCF";
		font-weight: 900;
    }

    .navbar .mobilemenu.show{
        display: flex;
    }

    .navbar .mobilelanguage{
        position: fixed;
        display: none;
        justify-content: space-evenly;
        width: 100%;
        bottom: 0px;
        left: 0px;
        padding-top: 55px;
        padding-bottom: 55px;
        padding-left: 30%;
        padding-right: 30%;
        z-index: 50;
    }

    .navbar .mobilelanguage.show{
        display: flex;
    }

    .navbar .mobilelanguage button{
        margin-left: 5px;
        margin-right: 5px;
        width: 80px;
        height: 80px;
        font-size: 32px;
        color: #EDEDED;
        border: 1px solid transparent;
        background-color: transparent;
        cursor: pointer;
        opacity: 60%;
        font-family: "IntegralCF";
		font-weight: bold;
    }
    
    .navbar .mobilelanguage button.selected{
        color: white !important;
        border: 1px solid white !important;
        opacity: 100%;
    }
}

@media (max-width: 425px) {
    .navbar{
        padding-left: 15px;
        padding-right: 15px;
        height: 125px;
    }

    .navbar .logo{
        z-index: 100;
        display: none;
    }

    .navbar .mobilelogo{
        z-index: 100;
        display: flex;
    }

    .navbar .options{
        display: none;
    }

    .navbar .mobileoptions{
        display: block;
        z-index: 100;
    }

    .navbar .mobilemenu{
        padding-top: 50%;
        padding-bottom: 50%;
    }

    .navbar .mobilemenu a{
        font-size: 34px;
    }

    .navbar .mobilelanguage button{
        width: 72px;
        height: 72px;
        font-size: 24px;
    }
}